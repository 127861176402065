import React, { useState, useEffect } from 'react';
import LoginForm from './components/LoginForm';
import DefaultPlayer from './components/Player';
import DefaultHeader from './components/Header';
import './App.css';

// Dynamically resolve tenant slug from subdomain or ENV fallback
const resolveTenant = () => {
    const fallback = process.env.REACT_APP_TENANT || 'default';
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0]; // e.g., hiphop105
    return subdomain || fallback;
};

function App() {
    const tenant = resolveTenant();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [session, setSession] = useState(null);
    const [loading, setLoading] = useState(true);
    const [TenantPlayer, setTenantPlayer] = useState(null);
    const [TenantHeader, setTenantHeader] = useState(() => DefaultHeader);

    // Add a data attribute for tenant-based theming (optional future)
    useEffect(() => {
        document.body.setAttribute('data-tenant', tenant);
    }, [tenant]);

    // Try to dynamically load the tenant-specific Player component
    useEffect(() => {
        const tryLoadOverride = async () => {
            try {
                const module = await import(`./tenants/${tenant}/Player.js`);
                setTenantPlayer(() => module.default);
                console.log(`Loaded tenant-specific Player for ${tenant}`);
            } catch (error) {
                console.warn(`No override Player found for tenant "${tenant}", using default.`);
                setTenantPlayer(() => DefaultPlayer);
            }

            try {
                const headerModule = await import(`./tenants/${tenant}/Header.js`);
                setTenantHeader(() => headerModule.default);
                console.log(`Loaded tenant-specific Header for ${tenant}`);
            } catch (error) {
                console.warn(`No override Header found for tenant "${tenant}", using default.`);
                setTenantHeader(() => DefaultHeader);
            }
        };

        tryLoadOverride();
    }, [tenant]);

    useEffect(() => {
        const checkOrCreateSession = async () => {
            try {
                let sessionId = localStorage.getItem('sessionId'); // Try reading from localStorage
                if (!sessionId) {
                    console.log('No session cookie found. Creating session...');
                    const createResponse = await fetch('/api_usermanagement/sessions/create-anonymous-session', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            session_id: 'anonTest',
                            data: { ip: '127.0.0.1', preferences: {} }
                        })
                    });

                    sessionId = createResponse.headers.get('x-session-token');
                    console.log('Session ID from header:', sessionId);
                    if (sessionId) {
                        localStorage.setItem('sessionId', sessionId);
                    } else {
                        console.error('No session ID returned from API!');
                        setLoading(false);
                        return;
                    }

                    const createData = await createResponse.json();
                    console.log('Create session response:', createData);
                }

                // Now check the session
                const checkResponse = await fetch(`/api_usermanagement/sessions/check-session/${sessionId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-session-token": sessionId
                    }
                });
                const data = await checkResponse.json();
                console.log('Check session response:', data);

                if (data.valid) {
                    setSession(data.session);
                    setIsLoggedIn(true); // Update this based on your logic
                } else {
                    setIsLoggedIn(false);
                }
            } catch (error) {
                console.error('Error checking/creating session:', error);
            } finally {
                setLoading(false);
            }
        };

        checkOrCreateSession();
    }, []);

    if (loading || !TenantPlayer || !TenantHeader) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <TenantHeader />
            <div className="app-container">
                {isLoggedIn ? (
                    <>
                        <button onClick={() => setIsLoggedIn(false)}>Logout</button>
                        <TenantPlayer session={session} />
                    </>
                ) : (
                    <>
                        <LoginForm onLogin={() => setIsLoggedIn(true)} />
                        <TenantPlayer session={session} />
                    </>
                )}
            </div>
        </>
    );
}

export default App;
