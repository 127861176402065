import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeLow, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import './Player.css';

const Player = () => {
    const [playing, setPlaying] = useState(false);
    const [volume, setVolume] = useState(0.8);
    const [metadata, setMetadata] = useState({
        title: 'Stream',
        artist: 'Shaqfu Radio',
        cover: 'https://radiocustoms-whitelabel-albumart.s3.us-east-2.amazonaws.com/Other%20Images/Shaq-Fu-Radio-Logo-White-80.png',
        duration: 0,
        currentTime: 0,
        trackId: 'defaultTrack'
    });

    // We'll store "like" and "dislike" in state for UI demonstration
    const [like, setLike] = useState(false);
    const [dislike, setDislike] = useState(false);

    const streamUrl = 'https://radiocustoms.cdnstream1.com/shaqfu';
    const metadataUrl = 'https://api.development.shaqfuradio.com/metadata';

    const togglePlay = () => setPlaying(prev => !prev);

    // Helper to get sessionId cookie
    const getCookie = (name) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? match[2] : null;
    };

    // Truncate title to 15 chars + ellipsis
    const truncatedTitle = metadata.title.length > 15
        ? metadata.title.slice(0, 15) + '...'
        : metadata.title;

    // Fetch metadata periodically
    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const { data } = await axios.get(metadataUrl);
                console.log("Fetched Metadata:", data);

                // Check if metadata starts with "adwtag" (ad logic)
                if (data.title?.startsWith("adwtag")) {
                    setMetadata(prev => ({
                        ...prev,
                        trackId: data.track_id ?? prev.trackId,
                        title: "Thanks for listening!",
                        artist: "ShaqFu Radio",
                        cover: "https://radiocustoms-whitelabel-albumart.s3.us-east-2.amazonaws.com/Other%20Images/Shaq-Fu-Radio-Logo-White-80.png",
                        duration: prev.duration,
                        currentTime: playing ? prev.currentTime : 0
                    }));
                } else {
                    setMetadata(prev => ({
                        ...prev,
                        trackId: data.track_id ?? prev.trackId,
                        title: data.title,
                        artist: data.artist,
                        cover: data.cover,
                        duration: data.duration || prev.duration,
                        currentTime: playing ? prev.currentTime : 0
                    }));
                }
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        fetchMetadata();
        const interval = setInterval(fetchMetadata, 10000);
        return () => clearInterval(interval);
    }, [playing]);

    // Simulate track progress
    useEffect(() => {
        let interval;
        if (playing) {
            interval = setInterval(() => {
                setMetadata(prev => ({
                    ...prev,
                    currentTime: prev.currentTime < prev.duration
                        ? prev.currentTime + 1
                        : prev.duration
                }));
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [playing]);

    // Handle like click
    const handleLike = async () => {
        try {
            const sessionId = localStorage.getItem('sessionId');
            if (!sessionId) {
                console.warn('No sessionId found in storage!');
                return;
            }
            console.log('Updating like for trackId:', metadata.trackId);
            setLike(true);
            setDislike(false);
            // Send title and artist in case trackId is null
            await fetch('/api_usermanagement/sessions/update-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-session-token': sessionId
                },
                body: JSON.stringify({
                    session_id: sessionId,
                    trackId: metadata.trackId, // may be null
                    title: metadata.title,
                    artist: metadata.artist,
                    preference: { like: true, dislike: false }
                })
            });
        } catch (error) {
            console.error('Error updating like preference:', error);
        }
    };

    // Handle dislike click
    const handleDislike = async () => {
        try {
            const sessionId = localStorage.getItem('sessionId');
            if (!sessionId) {
                console.warn('No sessionId found in storage!');
                return;
            }
            console.log('Updating dislike for trackId:', metadata.trackId);
            setDislike(true);
            setLike(false);
            await fetch('/api_usermanagement/sessions/update-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-session-token': sessionId
                },
                body: JSON.stringify({
                    session_id: sessionId,
                    trackId: metadata.trackId, // may be null
                    title: metadata.title,
                    artist: metadata.artist,
                    preference: { like: false, dislike: true }
                })
            });
        } catch (error) {
            console.error('Error updating dislike preference:', error);
        }
    };

    return (
        <div className="player-container">
            {/* Album Art Section */}
            <div className="album-art">
                <img src={metadata.cover} alt="Album Art" />
            </div>

            {/* Metadata Section */}
            <div className="metadata">
                <h1 className="title">{truncatedTitle}</h1>
                <h2 className="artist">{metadata.artist}</h2>

                {/* Thumbs Up/Down Buttons */}
                <div className="like-buttons">
                    <button
                        className={`thumbs-up ${like ? 'active' : ''}`}
                        onClick={handleLike}
                        aria-label="Like"
                    >
                        <FontAwesomeIcon icon={faThumbsUp} />
                    </button>
                    <button
                        className={`thumbs-down ${dislike ? 'active' : ''}`}
                        onClick={handleDislike}
                        aria-label="Dislike"
                    >
                        <FontAwesomeIcon icon={faThumbsDown} />
                    </button>
                </div>
            </div>

            {/* React Player */}
            <ReactPlayer
                className="react-player"
                url={streamUrl}
                playing={playing}
                volume={volume}
                controls={false}
                width="0"
                height="0"
                config={{
                    file: {
                        attributes: {
                            preload: 'auto',
                            buffer: 3
                        }
                    }
                }}
            />

            {/* Controls Section */}
            <div className="controls">
                {/* Play/Pause Button */}
                <button
                    aria-label={playing ? 'Pause' : 'Play'}
                    onClick={togglePlay}
                    className={`play-button ${playing ? 'playing' : ''}`}
                >
                    <svg viewBox="0 0 24 24" width="24" height="24">
                        <path fill="none" d="M0 0h24v24H0z" />
                        {playing ? (
                            <path d="M6 4h4v12h-4zM14 4h4v12h-4z" />
                        ) : (
                            <path d="M8 5v14l11-7z" />
                        )}
                    </svg>
                </button>

                {/* Track Progress */}
                <div className="track-progress">
                    <span className="current-time">
                        {Math.floor(metadata.currentTime / 60)}:
                        {Math.floor(metadata.currentTime % 60)
                            .toString()
                            .padStart(2, '0')}
                    </span>
                    <input
                        type="range"
                        min="0"
                        max={metadata.duration}
                        value={metadata.currentTime}
                        onChange={(e) =>
                            setMetadata({
                                ...metadata,
                                currentTime: parseFloat(e.target.value)
                            })
                        }
                        className="progress-bar"
                    />
                    <span className="remaining-time">
                        {Math.floor((metadata.duration - metadata.currentTime) / 60)}:
                        {Math.floor((metadata.duration - metadata.currentTime) % 60)
                            .toString()
                            .padStart(2, '0')}
                    </span>
                </div>
            </div>

            {/* Volume Control Section */}
            <div className="volume-control">
                <FontAwesomeIcon icon={faVolumeLow} className="volume-icon" />
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={volume}
                    onChange={(e) => setVolume(parseFloat(e.target.value))}
                />
            </div>
        </div>
    );
};

export default Player;
