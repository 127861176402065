import React, { useState } from 'react';
import './Header.css'; // New stylesheet for header styles
import logoImage from '../assets/images/Shaq-Fu-Radio-Logo-White-80.png';

const Header = () => {
const [showLogin, setShowLogin] = useState(false);
  return (
    <header className="header">
      <div className="left">
        <img src={logoImage} alt="Shaq-Fu Radio Logo" className="logo" />
      </div>
      <nav className="center">
        <ul>
          <li><a href="/">HOME</a></li>
          <li><a href="/dj-mixes">DJ MIXES</a></li>
          <li><a href="/recipes">SHAQ’S RECIPES</a></li>
          <li><a href="/fitness">DIESEL ROC FITNESS MIX</a></li>
          <li><a href="/music">MUSIC</a></li>
          <li><a href="/movies">MOVIES</a></li>
          <li><a href="/podcasts">PODCASTS</a></li>
          <li><a href="/contact">CONTACT</a></li>
        </ul>
      </nav>
      <div className="right">
        {/* Social Icons */}
        <a href="https://twitter.com/shaqfu_radio" className="btIconWidget" target="_blank" rel="noopener noreferrer">
          <div className="btIconWidgetIcon"><i className="fab fa-twitter"></i></div>
        </a>
        <a href="https://www.instagram.com/shaqfu_radio" className="btIconWidget" target="_blank" rel="noopener noreferrer">
          <div className="btIconWidgetIcon"><i className="fab fa-instagram"></i></div>
        </a>
        <a href="https://www.facebook.com/shaqfuradio" className="btIconWidget" target="_blank" rel="noopener noreferrer">
          <div className="btIconWidgetIcon"><i className="fab fa-facebook-f"></i></div>
        </a>
        <a href="https://www.snapchat.com/add/shaqfuradio" className="btIconWidget" target="_blank" rel="noopener noreferrer">
          <div className="btIconWidgetIcon"><i className="fab fa-snapchat-ghost"></i></div>
        </a>
        <a href="https://www.youtube.com/channel/UCbUyMO3p_ejrIJX-UqsdMcA" className="btIconWidget" target="_blank" rel="noopener noreferrer">
          <div className="btIconWidgetIcon"><i className="fab fa-youtube"></i></div>
        </a>
        <a href="/account" onClick={(e) => { e.preventDefault(); setShowLogin(!showLogin); }}>
        <i className="fas fa-user-circle" title="Account" />
        </a>
      </div>
      {showLogin && (
        <form className="login-form">
          <input type="text" placeholder="Username" />
          <input type="password" placeholder="Password" />
        </form>
      )}
    </header>
  );
};

export default Header;
